@font-face {
font-family: '__roboto_99b588';
src: url(/_next/static/media/6308fd88834bc55c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__roboto_99b588';
src: url(/_next/static/media/555f435cfe4c7d65-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__roboto_99b588';
src: url(/_next/static/media/ba3747b87728af70-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__roboto_99b588';
src: url(/_next/static/media/a22de4b82bf307c2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__roboto_99b588';
src: url(/_next/static/media/9d1fa45d50c4b57a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__roboto_Fallback_99b588';src: local("Arial");ascent-override: 104.11%;descent-override: 27.40%;line-gap-override: 0.00%;size-adjust: 89.11%
}.__className_99b588 {font-family: '__roboto_99b588', '__roboto_Fallback_99b588'
}

